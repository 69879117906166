import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Label } from '../components/Label';
import { PrivateRoute } from '../components/PrivateRoute';
import { SEO } from '../components/Seo';
import { H4, Typography } from '../components/Typography';
import { useModal } from '../contexts/filerModal';
import { userSelector } from '../selectors/user';
import { Device } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Profile: FC<RouteComponentProps> = (props) => {
  const user = useSelector(userSelector);
  const { open } = useModal();

  return (
    <PrivateRoute {...props}>
      <SEO title="Profile" />
      <Container className="flex flex-col flex-1 bg-white w-full">
        <H4 className="mb-6 text-center">User Profile</H4>

        <div className="flex items-end mb-4">
          <Input
            label="Email"
            className="mr-4"
            disabled
            inputStyle={{ color: '#333' }}
            containerStyle={{ flexGrow: 1 }}
            value={user?.email}
          />
          <Button className="flex-grow-0">Reset password</Button>
        </div>

        <div className="flex flex-col">
          <Label>E-filer status</Label>

          <div className="flex items-center">
            <Typography
              color={user?.isLicenseKeySet ? Colors.Blue500 : 'black'}
              className="mr-4 inline-block">
              {user?.isLicenseKeySet ? 'Authenticated' : 'Not set'}
              {user?.isLicenseKeySet && <FiCheckCircle className="ml-2 inline-block" />}
            </Typography>
            <Button onClick={open}>Set license key</Button>
          </div>
        </div>
      </Container>
    </PrivateRoute>
  );
};

export default Profile;
