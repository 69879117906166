import { createContext, useContext } from 'react';

interface UserContext {
  isAuthenticated: boolean;
}

const UserContext = createContext<UserContext>({ isAuthenticated: false });

const useUserContext = (): UserContext => useContext(UserContext);

export { UserContext, useUserContext };
