import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-link';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { UserContext } from '../contexts/me';
import { Paths } from '../paths';
import { authSelector } from '../selectors/auth';
import { Layout } from './Layout';

export const PrivateRoute: FC<RouteComponentProps> = (props) => {
  const { children } = props;
  const isAuthenticated = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthenticated) navigate(Paths.SignIn);
  }, [isAuthenticated]);

  return (
    <UserContext.Provider value={{ isAuthenticated }}>
      <Layout isAuthenticated={isAuthenticated}>{children}</Layout>
    </UserContext.Provider>
  );
};
